import { captureException, captureMessage } from '@sentry/browser'

export const logAndCaptureException: typeof captureException = (exception, ...rest) => {
  console.error(exception)

  // avoid reporting to Sentry in development environment
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  return captureException(exception, ...rest)
}

export const logAndCaptureMessage: typeof captureMessage = (message, ...rest) => {
  console.error(message)

  // avoid reporting to Sentry in development environment
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  return captureMessage(message, ...rest)
}

export class CustomError extends Error {
  public override readonly cause: Error | undefined

  override name = 'CustomError'

  constructor(message?: string, cause?: Error) {
    // 'Error' breaks prototype chain here
    super(message)

    // restore prototype chain
    Object.setPrototypeOf(this, new.target.prototype)

    this.cause = cause
  }

  get [Symbol.toStringTag](): string {
    return this.name
  }
}

type SerializableResponse = Response & {
  toJSON(): unknown
}

// assist serialization of response for Sentry
function responseToJSON(this: Response) {
  const { ok, status, statusText, type, url } = this
  const headers: Record<string, string[] | string | undefined> = {}
  this.headers.forEach((value, key) => {
    const existing = headers[key]
    if (Array.isArray(existing)) {
      existing.push(value)
    } else if (existing) {
      headers[key] = [existing, value]
    } else {
      headers[key] = value
    }
  })
  return {
    headers,
    ok,
    status,
    statusText,
    type,
    url,
  }
}

const toSerializableResponse = (response: Response): SerializableResponse => {
  const result = response as SerializableResponse
  result.toJSON = responseToJSON
  return result
}
export class FetchError extends CustomError {
  override name = 'FetchError'
  public readonly request?: Request
  public readonly response?: SerializableResponse
  public readonly responseBody?: unknown

  constructor(message: string, response?: Response, responseBody?: unknown) {
    super(message)
    if (response) this.response = toSerializableResponse(response)
    if (responseBody) this.responseBody = responseBody
  }
}

export function isLeftWithError(left: object): left is { error: Error } {
  const error = (left as { [prop: string]: unknown }).error
  return error instanceof Error
}

/**
 * Utility class for constructing `Error` from `Left` result
 *
 * Useful when `error` property in result is not present or does not contain an `Error` object
 */
export class ResultError extends CustomError {
  override name = 'ResultError'

  constructor(left: object) {
    const partial = left as { [prop: string]: unknown }
    super(
      (typeof partial.message === 'string' && partial.message) ||
        'Failed result captured without message',
    )
    const { _tag, ...propsToAssign } = partial
    Object.assign(this, propsToAssign)
  }
}

export const logAndCaptureLeftResult = <L>(
  result: ({ _tag: 'Left' } & L) | { _tag: 'Right' },
): string =>
  result._tag === 'Left'
    ? logAndCaptureException(isLeftWithError(result) ? result.error : new ResultError(result))
    : ''

/**
 * General purpose error to represent invalid response from server
 */
export class InvalidResponseError extends CustomError {
  override name = 'InvalidResponseError'
}
