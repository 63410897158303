import { GraphQLError } from 'graphql'

import { CustomError } from '../../utils/errorTools'
import { AutoRemoveCartItemError, AutoUpdateCartItemError } from './state/types'

export type CartUpdateOperation =
  | 'updateBillingAddress'
  | 'updateDropShip'
  | 'updatePaymentMethod'
  | 'updateShippingAddress'

export class CardExpiredError extends CustomError {
  override name = 'CardExpiredError'
}

export class CardTypeInvalidError extends CustomError {
  override name = 'CardTypeInvalidError'
}

export class CartUpdateError extends CustomError {
  override name = 'CartUpdateError'
  public readonly operation: CartUpdateOperation

  constructor(operation: CartUpdateOperation, cause?: Error) {
    super(`Operation "${operation}" failed`, cause)
    this.operation = operation
  }
}

export class UpdateErrantItemsError extends CustomError {
  override name = 'UpdateErrantItemsError'
  public readonly itemErrors: ReadonlyArray<AutoUpdateCartItemError>

  constructor(itemErrors: ReadonlyArray<AutoUpdateCartItemError>, cause: Error) {
    super(`Could not update errant items in cart`, cause)
    this.itemErrors = itemErrors
  }
}

export class RemoveErrantItemsError extends CustomError {
  override name = 'RemoveErrantItemsError'
  public readonly itemErrors: ReadonlyArray<AutoRemoveCartItemError>

  constructor(itemErrors: ReadonlyArray<AutoRemoveCartItemError>, cause: Error) {
    super(`Could not remove errant items from cart`, cause)
    this.itemErrors = itemErrors
  }
}

export class UnexpectedCartError extends CustomError {
  override name = 'UnexpectedCartError'
}

export function isCartUpdateError(err: unknown): err is CartUpdateError {
  return (
    err instanceof Error &&
    err.name === 'CartUpdateError' &&
    typeof (err as Partial<CartUpdateError>).operation === 'string'
  )
}

const invalidCouponCodeRegEx = /Coupon code is not valid|coupon code isn't valid/
export const invalidCouponCodeMessage = 'Invalid coupon code entered.'

export function messageFromAddCouponError(err: GraphQLError): string {
  const { message } = err
  // translate invalid coupon code message for consistency
  if (invalidCouponCodeRegEx.test(message)) {
    return invalidCouponCodeMessage
  }
  // use invalid coupon code message as fallback
  return message || invalidCouponCodeMessage
}
