import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultContext,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import fetch from 'isomorphic-fetch'
import React, { useContext, useEffect, useRef } from 'react'
import { switchMap, take, tap } from 'rxjs'

import { TokenContext } from '../layouts/context'
import { latestAuthStateWithAccessToken$ } from '../lib/auth/state'
import {
  ApolloClients,
  ApolloClientsContext,
  latestApolloClients$,
  setApolloClients,
} from '../lib/graphql/apollo'
import { FetchError, logAndCaptureException } from '../utils/errorTools'
import { fromApolloObservable, toApolloObservable } from '../utils/rx/apollo-observable'

/**
 * Custom fetch function to handle errors from the server. Apollo Router will return a non-JSON
 * error message if it catches the timeout. Check the response status and throw an error before
 * Apollo tries to parse the response as JSON.
 */
const customFetch = async (uri: URL, options: RequestInit | undefined) => {
  const response = await fetch(uri, options)
  if (response.status >= 400) {
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    const responseBody = await response.text()
    const responseBodySummary =
      responseBody.length > 100 ? `${responseBody.slice(0, 100)}...` : responseBody
    return Promise.reject(
      new FetchError(
        `${response.status}${statusText}${responseBodySummary ? ': ' : ''}${responseBodySummary}`,
        response,
        responseBody,
      ),
    )
  }
  return response
}

const commaLink = new HttpLink({
  uri: process.env.GATSBY_SEARCH_URL,
  fetch: customFetch,
})

const magentoLink = new HttpLink({
  uri: process.env.GATSBY_MAGENTO_URL,
  fetch,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // NOTE cast can be removed after upgrading to apollo-client 3.7.5
  // https://github.com/apollographql/apollo-client/pull/10402
  const { token, useLatestTokenIfLoggedIn } = operation.getContext() as DefaultContext

  // Much of existing code uses a token context and then sets it in the operation context. We've
  // added a new context option to automatically use the latest token if the user is logged in.
  // This might reduce the need for some boilerplate.
  if (useLatestTokenIfLoggedIn) {
    return toApolloObservable(
      latestAuthStateWithAccessToken$.pipe(
        take(1),
        tap((authState) => {
          if (authState.isAuthenticated) {
            operation.setContext({
              headers: {
                Authorization: `Bearer ${authState.accessToken}`,
              },
            })
          }
        }),
        switchMap(() => fromApolloObservable(forward(operation))),
      ),
    )
  }

  // Set token explicitly from context
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
  return forward(operation)
})

// Client for Commerce API
const commaClient = new ApolloClient({
  link: authMiddleware.concat(commaLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          eScripts: relayStylePagination(['orderBy', 'query']),
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  // Dev tools does not currently support multiple clients. Feature should be available soon,
  // though: https://github.com/apollographql/apollo-client-devtools/issues/822
  connectToDevTools: true,
})

// Magento client is used as the default client
const magentoClient = new ApolloClient({
  link: authMiddleware.concat(magentoLink),
  cache: new InMemoryCache({
    typePolicies: {
      Cart: {
        fields: {
          available_payment_methods: {
            merge: false,
          },
        },
      },
      // use `email` field for M2 customer type since `id` in response is null
      Customer: {
        keyFields: ['email'],
      },
      // specify key fields for types without `id` field
      CustomerAutoShip: {
        keyFields: ['autoShipId'],
      },
      CustomerOrder: {
        keyFields: ['orderId'],
      },
      CustomerInvoice: {
        keyFields: ['invoiceId'],
      },
      CustomerRebateRedemptionCashRequest: {
        keyFields: ['requestId'],
      },
      CustomerSettings: {
        keyFields: ['customerId'],
      },
      FacetedResultsAggregation: {
        keyFields: false,
      },
      SelectedCustomizableOption: {
        keyFields: false,
      },
      SimpleProduct: {
        keyFields: ['sku'],
      },
      TokenBaseCard: {
        keyFields: ['hash'],
      },
      Query: {
        fields: {
          getCustomerOrders: {
            keyArgs: ['customerId'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                orders: [...(existing.orders || []), ...(incoming.orders || [])],
              }
            },
          },
          getCustomerInvoices: {
            keyArgs: ['customerId'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                invoices: [...(existing.invoices || []), ...(incoming.invoices || [])],
              }
            },
          },
          getCustomerRewardsOrders: {
            keyArgs: ['customerId', 'rewardsSource'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                orders: [...(existing.orders || []), ...(incoming.orders || [])],
              }
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  connectToDevTools: false,
})

const clients: ApolloClients = {
  comma: commaClient,
  magento: magentoClient,
}

const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const token = useContext(TokenContext)
  // initial value should be undefined
  const latestTokenRef = useRef<string | undefined>(token)
  useEffect(() => {
    // avoid resetting store when token initially received
    if (latestTokenRef.current && latestTokenRef.current !== token) {
      // TODO: does this have the desired effect since token is set in context during hook execution?
      // user signed out or token updated - clear cache and refetch
      magentoClient.resetStore().catch((err) => {
        logAndCaptureException(err)
      })
      commaClient.resetStore().catch((err) => {
        logAndCaptureException(err)
      })
    }
    latestTokenRef.current = token
  }, [token])

  // hold subscription for child components and set clients on mount
  useEffect(() => {
    const subscription = latestApolloClients$.subscribe()

    setApolloClients(clients)

    return () => subscription.unsubscribe()
  }, [])

  return (
    <ApolloClientsContext.Provider value={clients}>
      <ApolloProvider client={magentoClient}>{children}</ApolloProvider>
    </ApolloClientsContext.Provider>
  )
}

export default AuthorizedApolloProvider
