import { OAuthError, RedirectLoginOptions } from '@auth0/auth0-react'

import { sessionOrFallbackStorage } from '../../utils/storage'
import { callbackSlugs } from './config'

export interface Auth0AppState {
  returnTo?: string
}

const isOAuthError = (error?: Error): error is OAuthError =>
  error instanceof Error && 'error' in error && 'error_description' in error

const isValidAuth0AppState = (appState: unknown): appState is Auth0AppState =>
  !!appState && typeof appState === 'object'

export const isUserBlockedError = (error?: Error): boolean =>
  isOAuthError(error) &&
  error.error === 'unauthorized' &&
  error.error_description === 'user is blocked'

export const isInvalidStateError = (error?: Error): boolean =>
  !!error && error.message === 'Invalid state'

export const getLoginCallbackUrl = (): string => {
  return typeof window !== 'undefined' ? `${window.location.origin}/${callbackSlugs.login}` : ''
}

export const mergeLoginWithRedirectOptions = (
  options?: RedirectLoginOptions,
): RedirectLoginOptions => {
  const optionsObj = options || {}
  return {
    ...optionsObj,
    redirectUri: getLoginCallbackUrl(),
  }
}

export const getAuth0AppState = (): Auth0AppState | undefined => {
  const auth0SessionStr = sessionOrFallbackStorage.getItem('a0.spajs.txs')
  if (!auth0SessionStr) {
    return undefined
  }
  try {
    const auth0Session = JSON.parse(auth0SessionStr) as Record<string, unknown>
    if (
      auth0Session &&
      typeof auth0Session === 'object' &&
      isValidAuth0AppState(auth0Session.appState)
    ) {
      return auth0Session.appState
    }
    return undefined
  } catch {
    return undefined
  }
}
